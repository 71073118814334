.alert-box-ie {
  color: #ffffff;
  background-color: #000000;
}

// Start login animation
#login-bg {
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  background-image: url("../images/login/qaonline_mxns_3.jpg");

  .invalid-feedback {
    margin-top: 0.35rem;
    font-size: inherit;
    color: white;
    background-color: #d9534f;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid #f2a19f;
    border-radius: 0.25rem;
  }
}

// to prevent animation issues Safari target any modern browser but Safari and Internet Explorer (Chrome 28+, Firefox 22+, MS Edge-all)
@supports (not (-webkit-hyphens:none)) {
  #login-bg {
    animation-name: login-background;
    animation-duration: 30s;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes login-background {
    0% {
      background-image: url("../images/login/qaonline_mxns_3.jpg");
    }

    32% {
      background-image: url("../images/login/qaonline_mxns_3.jpg");
    }

    33% {
      background-image: url("../images/login/qaonline_mxns_2.jpg");
    }

    65% {
      background-image: url("../images/login/qaonline_mxns_2.jpg");
    }
    66% {
      background-image: url("../images/login/qaonline_mxns_1.jpg");
    }
    100% {
      background-image: url("../images/login/qaonline_mxns_1.jpg");
    }
  }
}

// End login animation

.bg-black-trans {
  background-color: rgba(0, 0, 0, 0.2);
}

.login-logo {
  max-width: 80%;
  height: auto;
  padding-left: 2px;
  padding-top: 1px;
}

.nav-link {
  color: #fff;
  font-size: 0.8rem;

  &:hover {
    color: #fff;
  }
}

.alert {
  z-index: 1;
}